
import './App.css';
import React, { useState, useRef } from 'react';
import confettyImg from './confetty.gif';
import dumbAudio from './dumbAudio.mp3';
import errorNo from './errorNo.mp3';

function App() {

const [yes, setYes] = useState('Are you a dumb?'); 
const [countNo, setCountNo] = useState(0);
const [answerWithYes, setanswerWithYes] = useState(false);
const [chooseTittle, setchooseTittle] = useState('Choose an answer!');
const [buzz, setBuzz] = useState(true);
const [reset, setReset] = useState(true);
const [confetty, setConfetty] = useState(true);

const audioRef = useRef(null);

function yesAnswer() {
  setYes('I knew it!')
  setanswerWithYes(true);
  setchooseTittle('\uD83D\uDE01');
  setReset(false);

  audioRef.current = new Audio(dumbAudio);
  audioRef.current.play()
 

}

function noAnswer() {
  setCountNo((newMove) => {
    if (newMove === 10) {
      return 1; 
    }
    return newMove + 1;
  });

  const noErrorSound = new Audio(errorNo);
  noErrorSound.play()
}

//functie pentru buzz cand este facut click pe x
function buzzEffect() {
  setBuzz((stateBuzz) => {
    if(stateBuzz === false) {
      return true;  
    }
    return false;  
  });

  setTimeout(() => {
    setBuzz(false);
  }, 50); 
}

//finctie poentru confetty
function confettyFunc() {
  setConfetty(false)
}

//functia de resetare a panoului
function resetAll() {
  setYes('Are you a dumb?')
  setanswerWithYes(false);
  setchooseTittle('Choose an answer!');
  setReset(true);
  setConfetty(true)
  if (audioRef.current) {
    audioRef.current.pause();
    audioRef.current.currentTime = 0; 
  }
}



  return (
    <>
    <h2 id='just-desktop'>Just for desktop version!</h2>
    <img src={confettyImg} alt='confetty' className={confetty?'confetty-none':'cofetty-Show'} />
  <div className='general-error'>
    
    <div className={buzz?"cadru-intrebare":"cadru-intrebare-buzz"} id={`move-cell-${countNo}`}>
    <div className='options-header'>
      <h3 id='custom-request'>{chooseTittle}</h3>
      <div className='exit' onClick={buzzEffect}>
        <div className='line1'></div>
        <div className='line2'></div>
      </div>
    </div>
      <div className='question'>
        <h1 className='main-question'>{yes}</h1>
      </div>
      <div className='answers'>
        <button className='yes-button' disabled={answerWithYes?true:false} onClick={() => {yesAnswer(); confettyFunc()}}>Yes</button>
        <button className='no-button' disabled={answerWithYes?true:false} onClick={noAnswer}>No</button>
      </div>
    </div>

   

  </div>
  <button className={reset?'reset-all-none':'reset-all'} onClick={resetAll}>Try again! {'\uD83D\uDE01'}</button>
  </>
  );
}

export default App;
